import React, { useEffect } from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0e1828;
    color: #fff;
    padding-top: 8rem;
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: block;
            width: 100%;
            padding: 4rem;
            h1 {
                font-family: 'Poppins';
                font-size: 4rem;
                line-height: 1.2;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 6rem;
            }
            h4 {
                font-size: 1.4rem;
                line-height: 1.4;
            }
            ul,
            ol {
                li {
                    font-size: 1.4rem;
                    line-height: 1.4;
                }
            }
            p {
                font-size: 1.4rem;
                line-height: 1.4;
                margin: 2rem 0;
            }
            td {
                border-bottom: 1px solid #fff;
                font-size: 1.4rem;
                line-height: 1.4;
            }
        }
    }
    @media only screen and (min-width: 768px) {
        padding-top: 15rem;
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                h1 {
                    font-size: 12rem;
                    line-height: 1;
                }
            }
        }
    }
`;

const CookiePolicyPage = ({ transitionStatus }) => {
    useEffect(() => {
        console.log('Cookie Policy Page', transitionStatus);
    }, [transitionStatus]);

    return (
        <Layout>
            <Seo title="Cookie Policy" />
            <Wrapper>
                <div className="outer-wrapper">
                    <div className="inner-wrapper">
                        <h1>
                            Cookie
                            <br /> Policy
                        </h1>
                        <h2>Information about our use of cookies</h2>
                        <p>
                            Our website uses cookies to distinguish you from other users of our
                            website. This helps us to provide you with a good experience when you
                            browse our website and also allows us to improve our site.
                        </p>
                        <p>
                            A cookie is a small file of letters and numbers that we store on your
                            browser or the hard drive of your computer if you agree. Cookies contain
                            information that is transferred to your computer’s hard drive.
                        </p>
                        <p>We use the following cookies:</p>

                        <ul>
                            <li>
                                Analytical/performance cookies. These allow us to recognise and
                                count the number of visitors and to see how visitors move around our
                                website when they are using it. This helps us to improve the way our
                                website works, for example, by ensuring that users are finding what
                                they are looking for easily.
                            </li>
                            <li>
                                Functionality cookies. These are used to recognise you when you come
                                to or return to our website. Should we bring in such functionality,
                                this would also enable us to personalise our content for you, greet
                                you by name and remember your preferences.
                            </li>
                        </ul>

                        <p>
                            You can find more information about the individual cookies we use and
                            the purposes for which we use them in the table below:
                        </p>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>
                                        <strong>Duration</strong>
                                    </td>
                                    <td>
                                        <strong>Purpose</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utma</td>
                                    <td>2 years from set/update</td>
                                    <td>
                                        Used to distinguish users and sessions. The cookie is
                                        created when the javascript library executes and no existing
                                        __utma cookies exists. The cookie is updated every time data
                                        is sent to Google Analytics.
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmt</td>
                                    <td>10 minutes</td>
                                    <td>Used to throttle request rate.</td>
                                </tr>
                                <tr>
                                    <td>__utmb</td>
                                    <td>30 mins from set/update</td>
                                    <td>
                                        Used to determine new sessions/visits. The cookie is created
                                        when the javascript library executes and no existing __utmb
                                        cookies exists. The cookie is updated every time data is
                                        sent to Google Analytics.
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmc</td>
                                    <td>End of browser session</td>
                                    <td>
                                        Not used in ga.js. Set for interoperability with urchin.js.
                                        Historically, this cookie operated in conjunction with
                                        the&nbsp;__utmb&nbsp;cookie to determine whether the user
                                        was in a new session/visit.
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmz</td>
                                    <td>6 months from set/update</td>
                                    <td>
                                        Stores the traffic source or campaign that explains how the
                                        user reached our site. The cookie is created when the
                                        javascript library executes and is updated every time data
                                        is sent to Google Analytics.
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmv</td>
                                    <td>2 years from set/update</td>
                                    <td>
                                        Used to store visitor-level custom variable data. This
                                        cookie is created when a developer uses
                                        the&nbsp;_setCustomVar&nbsp;method with a visitor level
                                        custom variable. This cookie was also used for the
                                        deprecated&nbsp;_setVar&nbsp;method. The cookie is updated
                                        every time data is sent to Google Analytics.
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>
                            You can block cookies by activating the setting on your browser that
                            allows you to refuse the setting of all or some cookies. However, if you
                            use your browser settings to block cookies, you may not be able to
                            access all or parts of our site or its functioning may be impaired.
                        </p>
                    </div>
                </div>
            </Wrapper>
        </Layout>
    );
};

export default CookiePolicyPage;
